import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme, getTheme } from 'src/theme';
import { GlobalStyles } from 'src/theme/global';
import { ThemeProvider } from 'styled-components';
import useSellerThemeValue from './hooks/useSellerTheme';

const ThemeDeps: React.FC = ({ children }) => {
  const { theme } = useSellerThemeValue();
  return (
    <MuiThemeProvider theme={getMuiTheme(theme)}>
      <ThemeProvider theme={getTheme(theme)}>
        {children}
        <GlobalStyles />
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default ThemeDeps;
