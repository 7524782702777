import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import ReactGA from 'react-ga4';
import Refresh from 'src/components/atoms/Refresh/refresh.component';
import TrackingLogo from 'src/components/atoms/TrackingLogo/tracking-logo.component';
import Map from 'src/components/atoms/TrackingMap';
import InfoSection from 'src/components/organisms/InfoSection/info-section.component';
import Tracking from 'src/components/organisms/Trackings/tracking.component';
import useInvoiceUtils from 'src/hooks/useInvoiceUtils';

import { Container, Elevated, Header, BackgroundedPageWrapper } from './tracking.styles';
import PoweredBy from 'src/components/atoms/PoweredBy';

const TrackingPage: React.FC = () => {
  const { isError, tracking, status } = useInvoiceUtils();
  ReactGA.send({ hitType: 'pageview', page: tracking });
  const history = useHistory();
  useMemo(() => {
    if (isError) {
      history.push('/not-found');
    }
  }, [isError, history]);
  const isManualHandle = status === 'MANUAL_HANDLE';

  const { height: windowHeight } = useWindowSize();
  return (
    <>
      {isManualHandle ? (
        <BackgroundedPageWrapper style={{ flexDirection: 'column' }}>
          <Container style={{ height: windowHeight }}>
            <Elevated style={{ bottom: 'unset', flexDirection: 'column', position: 'relative' }}>
              <Header style={{ justifyContent: 'space-between' }}>
                <TrackingLogo />
                <InfoSection />
              </Header>
              <Tracking />
              <PoweredBy />
            </Elevated>
          </Container>
        </BackgroundedPageWrapper>
      ) : (
        <Container data-cy="map-container" style={{ height: windowHeight }}>
          <Elevated style={{ bottom: 'unset' }}>
            <Header>
              <TrackingLogo />
              <InfoSection />
            </Header>
          </Elevated>
          <Map />
          <Elevated>
            <Tracking />
          </Elevated>
          <Refresh />
        </Container>
      )}
    </>
  );
};

export default TrackingPage;
