import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getInvoiceTracking } from 'src/services/InvoiceTracking/invoice-tracking.query';
import { InvoiceTracking } from 'src/services/InvoiceTracking/invoice-tracking.types';
import { useSetSellerThemeState } from '../useSellerTheme';
import { defaultTheme } from '../useSellerTheme/atom';

const defaultValue: InvoiceTracking = {
  created_at: new Date(),
  type: 'DELIVERY',
  eta: new Date(),
  last_delivery_type: '',
  status_name: 'CREATED',
  customer: {
    name: ''
  },
  route: {},
  volumes: [],
  destination_address: {
    latitude: 0,
    longitude: 0
  },
  source_address: {
    latitude: 0,
    longitude: 0,
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: ''
  },
  updated_at: ''
};

const useInvoiceTracking = (id?: string) => {
  const { pid } = useParams<{ pid: string }>();
  const uniqueId = useMemo(() => id || pid, [id, pid]);
  const { setTheme, setIsLoading } = useSetSellerThemeState();
  const { isLoading, ...result } = useQuery(
    ['invoice-tracking', uniqueId],
    () => getInvoiceTracking(uniqueId),
    {
      enabled: Boolean(uniqueId),
      refetchInterval: 10000,
      retry: false,
      onSuccess: (value) => {
        setTheme(value.theme || defaultTheme);
      },
      onError: () => {
        setTheme(defaultTheme);
      }
    }
  );

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  return {
    ...result,
    isLoading,
    invoice: result.data || defaultValue,
    tracking: uniqueId
  };
};

export default useInvoiceTracking;
