import { ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #adadad;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #8f8f8f;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #8f8f8f;
  }
`;

export const ShowButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    font-size: 1.5rem;
    font-weight: medium;
    padding: 1rem 0;
  }
`;
