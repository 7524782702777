import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 10px;
  padding-top: 15px;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  align-self: center;
  margin: 0;
  color: ${(p) => p.theme.colors.bgContrast};
`;
