import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useToggle } from 'react-use';
import Divider from 'src/components/atoms/Divider/divider.component';

import { Container, Row, Title } from './expandable-item.styles';

interface Props {
  title: string | React.ReactNode | ((isExpanded: boolean) => React.ReactNode | string);
  endAddornment?: React.ReactNode;
  last?: boolean;
}

const ExpandableItem: React.FC<Props> = ({ children, title, endAddornment, last = false }) => {
  const [isExpanded, toggle] = useToggle(false);

  return (
    <Container>
      <Row>
        <Title onClick={() => toggle()}>
          {typeof title === 'function' ? title(isExpanded) : title}
        </Title>{' '}
        {endAddornment}
        <IconButton onClick={() => toggle()}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Row>
      <Collapse in={isExpanded}>{children}</Collapse>
      {last === true || <Divider />}
    </Container>
  );
};

export default ExpandableItem;
