import styled from 'styled-components';

export const Line = styled.span`
  width: 100%;
`;

export const Container = styled.div`
  padding: 0.2rem 0;
`;

export const Hello = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;
  color: ${(p) => p.theme.colors.bgContrast};
  padding: 0 0px;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const MainColorText = styled.b`
  color: ${(p) => p.theme.colors.mainContrastAgainstBg};
`;

export const TrackingText = styled.b`
  font-size: 1.6rem;
`;

export const LastUpdatedAt = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  color: ${(p) => p.theme.colors.bgContrast};
  padding: 0 0px;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const PickupAt = styled.div`
  font-size: 1.6rem;
  color: ${(p) => p.theme.colors.bgContrast};
  padding: 0 0px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
`;

export const ExtraFields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${TrackingText} {
    margin-right: 1rem;
  }
`;
