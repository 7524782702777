/* eslint-disable @typescript-eslint/no-unused-vars */
import { getMetadataFieldNames } from '@abbiamo/order-metadata/dist';
import { useMemo } from 'react';
import TextSkeleton from 'src/components/atoms/Text/text.skeleton.component';
import RenderManager from 'src/components/shared/RenderManager/render-manager.component';
import useInvoiceUtils from 'src/hooks/useInvoiceUtils';

import {
  Container,
  Hello,
  LastUpdatedAt,
  Line,
  MainColorText,
  TrackingText,
  ExtraFields,
  PickupAt
} from './info-section.styles';
import { statusToStatusCircleIndex } from '../../../utils/statusToStatusCircleIndex';

const InfoSection: React.FC = () => {
  const {
    etaDate,
    carrierEtaDate,
    isCarrierDelivery,
    arriveAtDate,
    hasFailed,
    hasSucceeded,
    isCanceled,
    isLoading,
    isMultipleVolumes,
    isValidEta,
    lastUpdatedMap,
    name,
    hasDriverLocation,
    tracking,
    metadata,
    creation_origin,
    status,
    carrierName,
    carrierLink,
    invoiceUpdatedAt,
    invoiceType,
    invoice
  } = useInvoiceUtils();

  const handleContent = useMemo(() => {
    let content = <>O seu pedido chegará em breve</>;
    if (status === 'MANUAL_HANDLE') {
      content = (
        <>
          A situação do seu pedido sofreu uma atualização{' '}
          {invoiceUpdatedAt && <> em {invoiceUpdatedAt}</>}. Por favor, entre em contato com{' '}
          <u>
            <a
              href={carrierLink || 'https://abbiamolog.com'}
              target={'_blank'}
              style={{ cursor: 'pointer', color: 'inherit' }}>
              {carrierName}
            </a>
          </u>{' '}
          para mais informações
        </>
      );
      return content;
    }
    switch (invoiceType) {
      case 'DELIVERY': {
        if (etaDate && isValidEta) {
          content = etaDate && (
            <>
              O seu pedido chegará dia
              <MainColorText> {isCarrierDelivery ? carrierEtaDate : etaDate}</MainColorText>
            </>
          );
        }

        if (hasSucceeded) {
          content = (
            <>
              O seu pedido foi entregue em
              <MainColorText>{arriveAtDate && <> {arriveAtDate}</>}</MainColorText>
            </>
          );
        }

        if (hasFailed) {
          content = (
            <>
              Estivemos no seu endereço{' '}
              {arriveAtDate ? <MainColorText>no dia {arriveAtDate}</MainColorText> : ''} e não
              conseguimos entregar o seu pedido
            </>
          );
        }

        if (isCanceled) {
          content = <>Infelizmente o seu pedido foi cancelado pela marca</>;
        }
        return content;
      }
      case 'TAKEOUT': {
        content = <>Você poderá retirar o seu pedido em breve</>;
        if (status === 'DISPATCHED') {
          content = <>Você já pode retirar o seu pedido na loja</>;
        }
        if (hasSucceeded) {
          content = <>Você já retirou o seu pedido na loja</>;
        }
        return content;
      }
      case 'RETURN': {
        const statusIndex = statusToStatusCircleIndex(status, invoiceType);
        content = <>O seu pedido será coletado para devolução em breve</>;
        switch (statusIndex) {
          case 1:
            content = <>Devolução Confirmada, em breve nosso motorista estará no seu endereço</>;
            break;
          case 2:
            content = (
              <>Pedido em rota de coleta, em breve nosso motorista estará no seu endereço</>
            );
            break;
          case 3:
            content = <>Pedido coletado, muito obrigado</>;
            break;
        }
        return content;
      }
    }
  }, [
    invoiceType,
    status,
    etaDate,
    isValidEta,
    isCarrierDelivery,
    carrierEtaDate,
    hasSucceeded,
    arriveAtDate,
    hasFailed,
    isCanceled,
    carrierName,
    carrierLink,
    invoiceUpdatedAt
  ]);

  const message = useMemo(() => {
    const content = handleContent;
    const extra_fields = getMetadataFieldNames({
      language: 'pt-BR',
      creation_type: creation_origin,
      metadata
    });

    const source_address = invoice?.source_address;
    const takeoutAddress = `${source_address?.street}, ${source_address?.number} - ${source_address?.neighborhood} - ${source_address?.city} - ${source_address?.state}`;

    return (
      <Line data-cy="info-section">
        Olá {name}.<br /> {content}.
        <br />
        {invoiceType === 'TAKEOUT' && (
          <PickupAt>
            Retirada em:
            <a
              href={`http://maps.google.com.br/maps?q=${invoice.source_address.latitude},${invoice.source_address.longitude}`}
              style={{ cursor: 'pointer', color: 'inherit', marginLeft: '0.5rem' }}
              target="_blank">
              {' '}
              <MainColorText>{takeoutAddress}</MainColorText>
            </a>
          </PickupAt>
        )}
        <TrackingText>
          Código de rastreio: <MainColorText>{tracking}</MainColorText>
        </TrackingText>
        {extra_fields && (
          <ExtraFields>
            {Object.entries(extra_fields)
              .filter(([_, value]) => Boolean(value))
              .map(([key, value]) => (
                <TrackingText>
                  {key}: <MainColorText>{value}</MainColorText>
                </TrackingText>
              ))}
          </ExtraFields>
        )}
      </Line>
    );
  }, [name, etaDate, tracking, hasSucceeded, isMultipleVolumes, isValidEta, arriveAtDate]);

  return (
    <Container>
      <RenderManager
        loading={isLoading}
        loadingComponent={
          <Hello>
            <Line>
              <TextSkeleton w="30%" m="2px 0" />
            </Line>
            <Line>
              <TextSkeleton w="60%" m="2px 0" />
            </Line>
            <Line>
              <TextSkeleton w="20%" m="2px 0" />
            </Line>
          </Hello>
        }>
        <Hello>{message}</Hello>
        {hasDriverLocation && <LastUpdatedAt>{lastUpdatedMap}</LastUpdatedAt>}
      </RenderManager>
    </Container>
  );
};

export default InfoSection;
