import VolumeInfo from 'src/components/molecules/VolumeInfo/volume-info.component';
import VolumeInfoSkeleton from 'src/components/molecules/VolumeInfo/volume-info.skeleton.component';
import RenderManager from 'src/components/shared/RenderManager/render-manager.component';
import useInvoiceTracking from 'src/hooks/useInvoiceTracking/invoice-tracking.hook';

import { Container } from './tracking.styles';

const Tracking: React.FC = () => {
  const { invoice, isLoading } = useInvoiceTracking();
  const isTrackingAvailable = invoice?.status_name !== 'MANUAL_HANDLE';
  return (
    <Container>
      <RenderManager loading={isLoading} loadingComponent={<VolumeInfoSkeleton />}>
        {invoice.volumes.map((volume, index) => (
          <VolumeInfo
            status={invoice.status_name}
            number={index + 1}
            volume={volume}
            isTrackingAvailable={isTrackingAvailable}
            key={index}
            invoiceType={invoice.type}
          />
        ))}
      </RenderManager>
    </Container>
  );
};

export default Tracking;
