import { InvoiceTracking, Volume } from 'src/services/InvoiceTracking/invoice-tracking.types';

import { Container } from './volume-info.styles';
import Divider from 'src/components/atoms/Divider/divider.component';
import OrderStatus from 'src/components/molecules/OrderStatus/order-status.component';
import {
  defaultStatusList,
  failStatusList,
  returnStatusList,
  takeoutStatusList
} from 'src/components/molecules/OrderStatus/order-status.list';
import Products from '../Products/products.component';
import TrackHeader from 'src/components/molecules/TrackHeader/track-header.component';
import useInvoiceUtils from 'src/hooks/useInvoiceUtils';
import { useMemo } from 'react';

interface Props {
  volume: Volume;
  number: number;
  status: InvoiceTracking['status_name'];
  isTrackingAvailable: boolean;
  invoiceType: InvoiceTracking['type'];
}

const VolumeInfo: React.FC<Props> = ({
  status,
  volume,
  number,
  isTrackingAvailable,
  invoiceType
}) => {
  const { isCanceled, hasFailed } = useInvoiceUtils();
  const happyPathStatusList = useMemo(() => {
    switch (invoiceType) {
      case 'TAKEOUT':
        return takeoutStatusList;
      case 'DELIVERY':
        return defaultStatusList;
      case 'RETURN':
        return returnStatusList;
      default:
        return defaultStatusList;
    }
  }, [invoiceType]);
  const statusList = hasFailed ? failStatusList : happyPathStatusList;
  return (
    <Container>
      {isTrackingAvailable && (
        <>
          <TrackHeader number={number} />
          {!isCanceled && (
            <>
              <Divider />
              <OrderStatus status={status} statusList={statusList} type={invoiceType} />
            </>
          )}
          <Divider />
        </>
      )}
      <Products items={volume.items} />
    </Container>
  );
};

export default VolumeInfo;
