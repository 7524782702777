import { useCallback, useMemo, useState } from 'react';
import ExpandableItem from 'src/components/molecules/ExpandableItem/expandable-item.component';
import ProductInformation from 'src/components/molecules/ProductInformation/product-information.component';
import ProductTitle from 'src/components/molecules/ProductTitle/product-title.component';
import { Volume } from 'src/services/InvoiceTracking/invoice-tracking.types';
import { numberToBrl } from 'src/utils/numberToBrl';
import { Container, ShowButton } from './products.styles';

interface Props {
  items: Volume['items'];
}

const Products: React.FC<Props> = ({ items = [] }) => {
  const [showAll, setShowAll] = useState(false);
  const limitedItems = useMemo(() => {
    if (showAll) return items;
    const tempItems = [...items];
    tempItems.length = 0;
    return tempItems;
  }, [showAll, items]);
  const toggleShowAll = useCallback(() => {
    setShowAll((s) => !s);
  }, [setShowAll]);
  const shouldRenderShowAllButton = useMemo(() => items.length > 0, [items]);
  return (
    <Container data-cy="items-container">
      {limitedItems.map((i, index) => (
        <ExpandableItem
          title={(isExpanded) => <ProductTitle isExpanded={isExpanded} name={i.name} />}
          last={index === limitedItems.length - 1}
          key={index}>
          <ProductInformation
            thumbnail_url={i.thumb_url}
            price={numberToBrl(i.amount)}
            quantity={i.quantity}
          />
        </ExpandableItem>
      ))}
      {shouldRenderShowAllButton && (
        <ShowButton data-cy="show-all-button" onClick={toggleShowAll}>
          {showAll ? 'Esconder' : 'Mostrar'} Produtos
        </ShowButton>
      )}
    </Container>
  );
};

export default Products;
