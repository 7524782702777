import { Container, Title } from './track-header.styles';

interface Props {
  number: number;
}

const TrackHeader: React.FC<Props> = ({ number }) => {
  return (
    <Container>
      <Title>Pacote {number}</Title>
    </Container>
  );
};

export default TrackHeader;
