import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-height: 100vh;
    font-family: EuropaBold, 'Roboto';
    font-weight: bold;
  }
  * {
    box-sizing: border-box;
  }
  #root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  html{
    font-size: 62.5%;
    background-color: ${(props) => props.theme.colors.bg};
  }

  div{
    /* padding: 2rem; */
    &#root{
      padding: 0;
    }
  }

  @media (max-width: 1080) {
    html {
      font-size: 58%;
    }
  }

  @media (max-width: 600px) {
    html{
      font-size: 50%;
    }
  }

  p {
    font-size: 1.4rem;
  }


  /* *[class^="Mui"]{
    font-size: 1.5rem;
  } */

  @font-face {
  font-family: "EuropaBold";
  src: url("./fonts/EuropaBold/EuropaBold.ttf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #adadad;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #8f8f8f;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #8f8f8f;
  }

`;
