import Divider from 'src/components/atoms/Divider/divider.component';
import Text from 'src/components/atoms/Text/text.component';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;
  ${Divider} {
    margin: 0px 0;
  }
  .MuiCollapse-wrapper {
    padding-bottom: 10px;
  }
`;

export const Title = styled(Text)`
  flex: 1;
  font-size: 1.8rem;
  color: ${(p) => p.theme.colors.bgContrast};
  display: block;
  padding: 10px 0;
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
`;
