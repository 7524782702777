import { isBefore } from 'date-fns';
import { useMemo, useState } from 'react';
import { Driver } from 'src/services/InvoiceTracking/invoice-tracking.types';
import capitalize from 'src/utils/capitalize';
import formatDate from 'src/utils/formatDate';
import { formatEta, formatCarrierEta } from 'src/utils/formatEta';
import deepEqual from 'fast-deep-equal/react';
import useInvoiceTracking from '../useInvoiceTracking/invoice-tracking.hook';
import { statusToStatusCircleIndex } from '../../utils/statusToStatusCircleIndex';

const MS_IN_MINUTE = 60000;

const useInvoiceUtils = () => {
  const { data: invoice, isLoading, tracking, ...rest } = useInvoiceTracking();

  const isMultipleVolumes = useMemo(() => invoice?.volumes.length > 1 || false, [invoice]);

  const creationTime = useMemo(() => invoice?.route.started_at || null, [invoice]);

  const etaDate = useMemo(() => invoice?.eta && formatEta(invoice.eta, creationTime), [invoice]);

  const carrierEtaDate = useMemo(() => invoice?.eta && formatCarrierEta(invoice.eta), [invoice]);

  const isCarrierDelivery = useMemo(
    () =>
      invoice?.last_delivery_type !== 'PRIVATE-FLEET' && invoice?.last_delivery_type !== 'ABBIAMO',
    [invoice]
  );

  const isValidEta = useMemo(
    () => invoice?.eta && isBefore(new Date(), new Date(invoice.eta)),
    [invoice]
  );

  const arriveAtDate = useMemo(
    () => invoice?.arrived_at && formatDate(invoice.arrived_at),
    [invoice]
  );

  const hasSucceeded = useMemo(() => {
    return invoice?.status_name === 'SUCCESSFUL';
  }, [invoice]);

  const hasFailed = useMemo(() => invoice?.status_name === 'FAILED', [invoice]);

  const isCanceled = useMemo(() => invoice?.status_name === 'CANCELED', [invoice]);

  const name = useMemo(() => capitalize(invoice?.customer.name.split(' ')[0]), [invoice]);
  const lastUpdatedMap = useMemo(() => {
    if (!invoice?.driver_location) return '';
    const date = invoice.driver_location.timestamp;
    const formatedDate = formatDate(new Date(date));
    return `Ultima atualização do mapa: ${formatedDate}`;
  }, [invoice]);

  const [lastDriverLocation, setLastDriverLocation] = useState<{
    tracking: string;
    driver_location: Driver | null;
    lastUpdatedTime: number;
  }>({ tracking: tracking || '', driver_location: null, lastUpdatedTime: Date.now() });

  const driver: Driver | null = useMemo(() => {
    if (
      invoice?.driver_location &&
      !deepEqual(invoice.driver_location, lastDriverLocation.driver_location)
    ) {
      setLastDriverLocation({
        driver_location: invoice.driver_location,
        tracking: tracking,
        lastUpdatedTime: Date.now()
      });
      return invoice.driver_location;
    }
    if (lastDriverLocation.driver_location && lastDriverLocation.tracking === tracking) {
      return lastDriverLocation.driver_location;
    }
    return null;
  }, [invoice, lastDriverLocation]);

  const driverLocation = useMemo(() => {
    if (driver) {
      return {
        lat: driver.location.latitude,
        lng: driver.location.longitude
      };
    } else return null;
  }, [driver]);

  const customerLocation = useMemo(() => {
    return {
      lat: invoice?.destination_address.latitude,
      lng: invoice?.destination_address.longitude
    };
  }, [invoice]);

  const takeoutLocation = useMemo(() => {
    return {
      lat: invoice?.source_address.latitude,
      lng: invoice?.source_address.longitude
    };
  }, [invoice]);

  const hasDriverLocation = useMemo(() => {
    if (
      invoice?.type === 'RETURN' &&
      statusToStatusCircleIndex(invoice.status_name, invoice.type) === 3
    )
      return false;
    if (driver !== null && driver.timestamp >= Date.now() - 30 * MS_IN_MINUTE) return true;
    else return false;
  }, [driver]);

  const status = useMemo(() => {
    return invoice?.status_name;
  }, [invoice]);

  const carrierName = useMemo(() => {
    return invoice?.theme?.carrier_name;
  }, [invoice]);

  const carrierLink = useMemo(() => {
    return invoice?.theme?.logo_link;
  }, [invoice]);

  const invoiceUpdatedAt = useMemo(() => {
    return invoice?.updated_at ? formatDate(invoice.updated_at) : null;
  }, [invoice]);

  const invoiceType = useMemo(() => {
    return invoice?.type;
  }, [invoice]);

  const isReadyForPickup = useMemo(() => {
    return (invoice?.type === 'TAKEOUT' && invoice?.status_name === 'DISPATCHED') || false;
  }, [invoice]);

  return {
    ...rest,
    isLoading,
    carrierEtaDate,
    isCarrierDelivery,
    tracking,
    hasDriverLocation: hasDriverLocation,
    isMultipleVolumes,
    etaDate,
    isValidEta,
    arriveAtDate,
    hasSucceeded,
    hasFailed,
    isCanceled,
    name,
    lastUpdatedMap,
    driver,
    driverLocation,
    metadata: invoice?.metadata,
    creation_origin: invoice?.creation_origin,
    customerLocation,
    status,
    carrierName,
    carrierLink,
    invoiceUpdatedAt,
    invoiceType,
    takeoutLocation,
    isReadyForPickup
  };
};

export default useInvoiceUtils;
